.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.BorderButtonAnimation{
  border:none;
  background-color:offwhite;
  font-size:30px;
  padding:20px 40px;
  text-transform:capitalize;
  cursor:pointer;
  transform:all 0.3s;
  position:relative;
}
.BorderButtonAnimation:before{
  content:"";
  position :absolute;
  width:24px;
  height:24px;
  top:-5px;
    left:-5px;
  border-top:2px solid tomato;
  border-left:2px solid tomato;
  transition:all 0.25s;
}
.BorderButtonAnimation:hover:before ,.BorderButtonAnimation:hover:after{
  width:104%;
  height:110%;
}
.BorderButtonAnimation:after{
  content:"";
  position :absolute;
  width:24px;
  height:24px;
  bottom:-5px;
    right:-5px;
  border-bottom:2px solid tomato;
  border-right:2px solid tomato;
  transition:all 0.30s;
}